import { Routes } from '@angular/router';
import { BlogComponent } from './components/blog/blog.component';
import { DevComponent } from './components/dev/dev.component';
import { MusicComponent } from './components/music/music.component';

export const routes: Routes = [
  {
    path: 'blog',
    component: BlogComponent,
  },
  {
    path: 'dev',
    component: DevComponent,
  },
  {
    path: 'music',
    component: MusicComponent,
  },
];
