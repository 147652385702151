import { MusicInterface } from "../music.interface";

export const RUJA_O_LEÃO: MusicInterface = {
  title: 'RUJA O LEÃO',
  linearCompact: [],
  lyrics: {
    I: [],
    V: [
      'Sobre o trono de justiça',
      'Eternamente haverá um rei',
      'Ele voltará para governar as nações',
      'Em amor',
    ],
    C: [
      'E aquele fio que se rompeu',
      'Da minha alma até a Deus',
      'O sangue santo costurou',
      'Cristo em Suas linhas de amor',
      'E agora um homem feito eu',
      'Pode no homem ver a Deus',
      'Ter paz, viver, andar e ser',
      'Me fez amigo em Seu verter',
    ],

  },
  linear: [
    "I",
    "V",
    "C",
    "Ri",
    "V",
    "C",
    "In",
    "C",
    "C",
  ]
}