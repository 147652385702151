import {
  Component,
  ElementRef,
  ViewChild,
  ViewChildren,
  inject,
  signal,
} from '@angular/core';
import { BlogService } from '../../services/blog.service';
import { CommonModule, DatePipe, NgClass } from '@angular/common';
import { isLastPost, loadingPosts, posts } from '../../signals/blog.signals';

@Component({
  selector: 'app-blog',
  standalone: true,
  imports: [NgClass, DatePipe],
  templateUrl: './blog.component.html',
  styleUrl: './blog.component.scss',
})
export class BlogComponent {
  private readonly _blogService = inject(BlogService);

  firstOpened = true;
  isLoading = loadingPosts;
  isLastPost = isLastPost;
  posts = posts;
  topSize = signal(0);
  readingPostIndex: string | null = null;

  @ViewChild('visibleDiv', { static: true }) visibleDiv: ElementRef | undefined;

  @ViewChildren('post') postElements: ElementRef[] | undefined;

  ngOnInit() {
    this.infiniteLoad();
    this.getPosts();
  }

  infiniteLoad() {
    setTimeout(() => {
      setInterval(() => {
        this.setTopSize();
        if (this.hasMorePosts()) {
          this.getPosts();
        }
      }, 100);
    }, 0);
  }

  getPosts() {
    this._blogService.getPosts().subscribe({
      error: (err) => {
        console.log(err);
      },
    });
  }

  setTopSize = () => {
    let topSize =
      this.visibleDiv?.nativeElement.getBoundingClientRect().top * 0.6 -
      window.innerHeight;
    this.topSize.update(() => topSize);
  };

  hasMorePosts = () =>
    this.topSize() < 0 && !this.isLoading() && !this.isLastPost();

  readPost(postId: string) {
    if (this.readingPostIndex === postId || this.firstOpened) {
      this.firstOpened = false;
      this.readingPostIndex = '';
      this.scrollToPost(postId);
      return;
    }
    this.readingPostIndex = postId;
    this.scrollToPost(postId);
  }

  preventClick(event: Event) {
    if (document.getSelection()?.toString() !== '') {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  scrollToPost(postId: string) {
    const defaultMargin = 15;
    setTimeout(() => {
      let postElement = this.postElements?.find(
        (p) => p.nativeElement.id === postId,
      );
      if (postElement) {
        const fromTopView = postElement.nativeElement.offsetTop;
        window.scrollTo({
          top: fromTopView - defaultMargin,
          behavior: 'smooth',
        });
      }
    }, 0);
  }
}
