import { MusicInterface } from "../music.interface";

export const COLOSSENSES: MusicInterface = {
  title: 'COLOSSENSES E SUAS LINHAS DE AMOR',
  linearCompact: [],
  lyrics: {
    I: [],
    V: [
      'Lembrem da canção, Cristo é o retrato de Deus',
      'Do que foi feito antecedeu, primeiro',
      'Antes do nascer, fez justiça e em Si morreu',
      'Supremacia e tronos são, são Teus',
    ],
    C: [
      'E aquele fio que se rompeu',
      'Da minha alma até a Deus',
      'O sangue santo costurou',
      'Cristo em Suas linhas de amor',
      'E agora um homem feito eu',
      'Pode no homem ver a Deus',
      'Ter paz, viver, andar e ser',
      'Me fez amigo em Seu verter',
    ],

  },
  linear: [
    "I",
    "V",
    "C",
    "Ri",
    "V",
    "C",
    "In",
    "C",
    "C",
  ]
}