import { signal } from '@angular/core';
import { Post } from '../interfaces/blogger.interfaces';
import { BLOGGER_API_KEY } from '../../environments/environment.local';

export const loadingPosts = signal(false);
export const posts = signal([] as Post[]);
export const isLastPost = signal(false);
export const params = signal({
  params: {
    key: BLOGGER_API_KEY,
    maxResults: 25,
    labels: ['pensamentos'],
  },
});
export const pageToken = signal('');

export class BlogSignals {
  loadingPosts = loadingPosts;
  posts = posts;
  isLastPost = isLastPost;
  params = params;
  pageToken = pageToken;

  updatePageToken = (pageToken: string) =>
    this.pageToken.update(() => pageToken);

  updateNextParams = () =>
    this.params.update((value) => ({
      ...value,
      params: { ...value.params, pageToken: this.pageToken() },
    }));
  setIsLastPost = () => this.isLastPost.update(() => true);
  setLoadingPosts = (value: boolean) => this.loadingPosts.update(() => value);
  addPosts = (posts: Post[]) =>
    this.posts.update((oldPosts) => oldPosts.concat(posts));
}
