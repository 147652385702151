import { LinearCompact, Music, MusicInterface } from './music.interface';
import { COLOSSENSES } from './musics/colossenses';
import { RUJA_O_LEÃO } from './musics/ruja-o-leao';

// export const SESSION = (linear: LinearInterface[]) => `${linear}`;
const EXODO: MusicInterface = {
  title: 'EXODO',
  linearCompact: [],
  lyrics: {
    I: [],
    V1: [
      'O dia enfim chegou',
      'O cordeiro foi cortado',
      'Ele vem pra nos visitar',
      'Na porta o sangue está',
      'No crepúsculo da tarde',
      'Ele vem pra nos libertar'
    ],
    Pc1: [
      'Todo escravo será livre',
      'Nenhum choro se ouvirá',
      'Toda fome saciada',
      'Nossa voz Ele ouviu',
    ],
    C: [
      'Não há Deus como o nosso Deus',
      'Nossa força e canção',
      'Grandes coisas Ele fez',
      'Seu reino é pra sempre',
    ],
    V2: [
      'Na Cruz o sangue está',
      'É sexta-feira à tarde',
      'Ele veio para nos salvar',
      'Esmagado o filho foi',
      'Cristo está crucificado',
      'Morreu em nosso lugar'
    ],
    Pc2: [
      'Sua vida derramou',
      'Nenhuma condenação há',
      'Na montanha da caveira',
      'Ao nosso lado Deus está'
    ],
    Pt: [
      'Pra nos dar sua doce graça',
      'Nos trouxe a redenção',
      'Não há mais razão pra chorar',
    ]
  },
  linear: [
    "I",
    "V1",
    "Pc1",
    "C",
    "C",
    "V2",
    "Pc2",
    "C",
    "C",
    "Pt",
    "C",
    "C"
  ]
}

export const MUSICS: Music[] = [
  {
    ...EXODO,
    showMusic: true
  },
  {
    ...COLOSSENSES,
    showMusic: true
  },
  {
    ...RUJA_O_LEÃO,
    showMusic: true
  }

]