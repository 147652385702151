@if (posts()) {
  <div class="row">
    @for (post of posts(); track post.id) {
      <div
        (click)="readPost(post.id)"
        class="col-3 d-flex py-2 pointer"
        [ngClass]="
          readingPostIndex === post.id ||
          (readingPostIndex === null && post.id === posts()[0].id)
            ? 'col-12'
            : 'col-12 col-sm-3'
        "
      >
        <div #post [id]="post.id" class="card w-100 card-blog">
          <div class="post m-3 d-flex flex-column h-100">
            <div class="post-header">
              <div class="post-header__title">
                <h2>{{ post.title }}</h2>
              </div>
            </div>
            <div class="mt-auto ms-auto">
              <span class="ms-auto">
                <em>{{ post.published | date }}</em>
              </span>
            </div>
            @if (
              readingPostIndex === post.id ||
              (readingPostIndex === null && post.id === posts()[0].id)
            ) {
              <div (click)="preventClick($event)" class="post-body mt-3">
                <div [innerHTML]="post.content"></div>
              </div>
            }
          </div>
        </div>
      </div>
    }
  </div>
  <div class="row">
    <div class="col-12 d-flex justify-content-center">
      @if (isLoading()) {
        <div class="spinner-border text-dark" role="status"></div>
      }
    </div>
  </div>
}
<div #visibleDiv></div>
