import { NgClass, NgFor, UpperCasePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MusicInterface } from '../music.interface';

@Component({
  selector: 'app-linear',
  standalone: true,
  imports: [NgClass, NgFor, UpperCasePipe],
  templateUrl: './linear.component.html',
  styleUrl: './linear.component.scss'
})
export class LinearComponent {
  @Input() music: MusicInterface = {} as MusicInterface;
  @Input() showCompact = true;

}
