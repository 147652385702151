@if (showCompact) {
  @for (compact of music.linearCompact; track $index) {
    <div
      [ngClass]="compact.key"
      class="d-flex align-items-center justify-content-center music-map_container"
    >
      @if (compact.value > 1) {
        <div class="music-map_compact-time">
          <span class="text">{{ compact.value + "x" }}</span>
        </div>
      }
      <span class="music-map_session">
        {{ compact.key }}
      </span>
    </div>
  }
} @else {
  @for (line of music.linear; track $index) {
    <div
      [ngClass]="line"
      class="d-flex align-items-center justify-content-center music-map_container"
    >
      <span class="music-map_session">
        {{ line }}
      </span>
    </div>
  }
}
