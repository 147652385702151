export const environment = {
  production: false,
  BLOGGER_URL:
    'https://www.googleapis.com/blogger/v3/blogs/2359844949314143962',
  BLOGGER_API_KEY: 'AIzaSyAJVLmIwiyJONhHfGr_Sf71BVcnLr_k4tQ',
  BLOGGER_API_TOKEN:
    'ya29.a0AfB_byAhwlIbD_M3O9np-xh2fIfFYB3KzoNzCYa1k6o_ib3b6Em1dWakcNBnSQoXVCKz0RPy39aDxLWmcwAQ7oMHV-eBPwZTqIvBa8KEM4T_SpSxWiWIEDWj3uIFdLbPQvKKTwGJeukO2n3X_JItj1kMac5S-UbGUpf9aCgYKAa0SARMSFQHGX2MiMgTeV3BWy6XD7y4PRx3HQw0171',
};

export const BLOGGER_API_TOKEN = environment.BLOGGER_API_TOKEN;
export const BLOGGER_API_KEY = environment.BLOGGER_API_KEY;
export const BLOGGER_URL = environment.BLOGGER_URL;
