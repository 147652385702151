import { HttpClient } from '@angular/common/http';
import { Injectable, Signal, inject, signal } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { Post, PostsResponse } from '../interfaces/blogger.interfaces';
import {
  BLOGGER_API_KEY,
  BLOGGER_URL,
} from '../../environments/environment.local';
import { toSignal } from '@angular/core/rxjs-interop';
import { BlogSignals } from '../signals/blog.signals';

@Injectable({
  providedIn: 'root',
})
export class BlogService extends BlogSignals {
  _http = inject(HttpClient);

  getPosts(): Observable<PostsResponse> {
    if (this.isLastPost()) return new Observable();
    this.setLoadingPosts(true);
    return this._http
      .get<PostsResponse>(`${BLOGGER_URL}/posts`, this.params())
      .pipe(tap((postsResponse) => this.handleNextCall(postsResponse)));
  }

  handleNextCall(postsResponse: PostsResponse) {
    if (!postsResponse.nextPageToken) {
      this.setIsLastPost();
    }
    this.updatePageToken(postsResponse.nextPageToken ?? '');
    this.updateNextParams();
    this.addPosts(postsResponse.items);
    this.setLoadingPosts(false);
  }
}
