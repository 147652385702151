<div class="container-fluid">
  <div class="row">
    <div class="col-12 mb-4">
      <button
        class="btn btn-outline-secondary btn-compact btn-sm"
        (click)="toggleCompact()"
      >
        {{ btnCompactText | uppercase }}
      </button>
    </div>
    <div class="col-12">
      @for (music of musicList; let musicIndex = $index; track musicIndex) {
        <p class="music-title mb-4 pointer" (click)="toggleShowMusic(music)">
          {{ music.title }}
        </p>

        @if (music.showMusic) {
          <app-linear
            class="row music-map ms-1"
            [music]="music"
            [showCompact]="showCompact"
          ></app-linear>

          @if (!showCompact) {
            @for (session of music.linear; track $index) {
              <div class="session-block" [ngClass]="session">
                {{ session }}
                @for (verse of music.lyrics[session]; track $index) {
                  <div class="verse">
                    {{ verse }}
                  </div>
                }
              </div>
            }
          } @else {
            @for (session of music.linearCompact; track $index) {
              <div
                class="session-block position-relative"
                [ngClass]="session.key"
              >
                @if (session.value > 1) {
                  <span class="times">{{ session.value }} x</span>
                }
                {{ session.key }}
                @for (
                  verse of lyricsWithoutType[musicIndex][session.key];
                  let verseIndex = $index;
                  track verseIndex
                ) {
                  <div class="verse">
                    {{ verse }}
                  </div>
                }
              </div>
            }
          }
        }
      }
    </div>
  </div>
</div>
