import { NgClass, NgFor, NgIf, UpperCasePipe } from '@angular/common';
import { Component } from '@angular/core';
import { MUSICS } from './music.const';
import { LinearComponent } from "./linear/linear.component";
import { Music, MusicInterface, PureLyrics } from './music.interface';
// import { LINEAR_NAMES } from './music.interface';

@Component({
  selector: 'app-music',
  standalone: true,
  imports: [NgFor, NgClass, UpperCasePipe, LinearComponent, NgIf],
  templateUrl: './music.component.html',
  styleUrl: './music.component.scss'
})
export class MusicComponent {
  musicList = MUSICS;
  showCompact = true;
  btnCompactText = 'full';
  lyricsWithoutType: PureLyrics[] = [{} as PureLyrics];



  ngOnInit() {
    this.setCompactLinear();
  }

  toggleShowMusic(music: Music) {
    music.showMusic = !music.showMusic;
  }

  setCompactLinear() {
    this.musicList.forEach((music, index) => {
      this.lyricsWithoutType[index] = music.lyrics;
      console.log(this.lyricsWithoutType);
      music.linearCompact = [];
      for (let i = 0; i < music.linear.length; i++) {
        let key = music.linear[i];
        let linearCompact = {
          key,
          value: 1
        }
        while (music.linear[i + 1] === key) {
          linearCompact.value++;
          i++;
        }
        music.linearCompact.push(linearCompact);
      }
    });
    console.log(this.musicList);
  }

  toggleCompact() {
    this.showCompact = !this.showCompact;
    if (!this.showCompact) {
      this.btnCompactText = 'compact';
    } else {
      this.btnCompactText = 'full';
    }
  }


}
